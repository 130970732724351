import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ContentRoute, LayoutSplashScreen } from '../_metronic/layout';

const DashboardPage = React.lazy(() => import('./pages/DashboardPage'));

const Users = React.lazy(() => import('./pages/User/index'));
const UserCreate = React.lazy(() => import('./pages/User/Create'));
const UserEdit = React.lazy(() => import('./pages/User/Edit'));

const Events = React.lazy(() => import('./pages/Events/Index'));
const EventCreate = React.lazy(() => import('./pages/Events/Create'));
const EventEdit = React.lazy(() => import('./pages/Events/Edit'));
const EventView = React.lazy(() => import('./pages/Events/View'));
const EventPrivateGroups = React.lazy(() => import('./pages/Events/PrivateGroups'));

const ReportEarning = React.lazy(() => import('./pages/Report/Earning'));

const EventSetting = React.lazy(() => import('./pages/Settings/EventSetting'));
const PaymentSetting = React.lazy(() => import('./pages/Settings/PaymentSetting'));
const AppSetting = React.lazy(() => import('./pages/Settings/AppSetting'));

const UserProfile = React.lazy(() => import('./pages/Profile/Profile'));

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute exact path="/dashboard" component={DashboardPage} />

        {/*User Routes*/}
        <Route exact path="/users" component={Users} />
        <Route exact path="/users/new" component={UserCreate} />
        <Route exact path="/users/:id/edit" component={UserEdit} />

        {/*Event Route*/}
        <Route exact path="/events" component={Events} />
        <Route exact path="/events/new" component={EventCreate} />
        <Route exact path="/events/private-groups" component={EventPrivateGroups} />
        <Route exact path="/events/:id/edit" component={EventEdit} />
        <Route exact path="/events/:id" component={EventView} />

        <Route exact path="/reports/earnings" component={ReportEarning} />

        <Route exact path="/settings/event" component={EventSetting} />
        <Route exact path="/settings/payment" component={PaymentSetting} />
        <Route exact path="/settings/app" component={AppSetting} />

        <Route path="/user-profile" component={UserProfile} />
        <Redirect to="/404" />
      </Switch>
      <ToastContainer autoClose={5000} />
    </Suspense>
  );
}
