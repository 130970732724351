/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import SVG from 'react-inlinesvg';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { checkIsActive, toAbsoluteUrl } from '../../../../_helpers';

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open menu-item-not-hightlighted`
      : '';
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive('/dashboard', false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*Events Menu Item*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive('/users', true)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/users">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Group.svg')} />
            </span>
            <span className="menu-text">Users</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Users</span>
                  </span>
                </li>
                <li className={`menu-item ${getMenuItemActive('/users')}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/users">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">All Users</span>
                  </NavLink>
                </li>
                <li className={`menu-item ${getMenuItemActive('/users/new')}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/users/new">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Add New</span>
                  </NavLink>
                </li>
              </ul>
            </ul>
          </div>
        </li>

        {/*Events Menu Item*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive('/events', true)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/events">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Layout/Layout-4-blocks.svg')} />
            </span>
            <span className="menu-text">Events</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Events</span>
                  </span>
                </li>
                <li className={`menu-item ${getMenuItemActive('/events')}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/events">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">All Events</span>
                  </NavLink>
                </li>
                <li className={`menu-item ${getMenuItemActive('/events/private-groups')}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/events/private-groups">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Private Groups Request</span>
                  </NavLink>
                </li>
                <li className={`menu-item ${getMenuItemActive('/events/new')}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/events/new">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Add New</span>
                  </NavLink>
                </li>
              </ul>
            </ul>
          </div>
        </li>
        {/*Reports Menu Item*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive('/reports', true)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/reports">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Chart-pie.svg')} />
            </span>
            <span className="menu-text">Reports</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Reports</span>
                  </span>
                </li>
                <li className={`menu-item ${getMenuItemActive('/reports/earnings')}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/reports/earnings">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Earnings</span>
                  </NavLink>
                </li>
              </ul>
            </ul>
          </div>
        </li>
        {/*Settings Menu Item*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive('/settings', true)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/settings">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Settings.svg')} />
            </span>
            <span className="menu-text">Settings</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Settings</span>
                  </span>
                </li>
                <li className={`menu-item ${getMenuItemActive('/settings/app')}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/settings/app">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">App</span>
                  </NavLink>
                </li>
                <li className={`menu-item ${getMenuItemActive('/settings/event')}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/settings/event">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Event</span>
                  </NavLink>
                </li>
                <li className={`menu-item ${getMenuItemActive('/settings/payment')}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/settings/payment">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Payment</span>
                  </NavLink>
                </li>
              </ul>
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
